import React from 'react'
import { Link } from 'gatsby'

export default function Pagination({
  pageSize,
  totalCount,
  currentPage,
  base,
}) {
  const totalPages = Math.ceil(totalCount / pageSize)
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1

  const hasNextPage = nextPage <= totalPages
  const hasPrevPage = prevPage >= 1
  return (
    <div className="Pagination flex justify-around bg-gray-100 rounded-md border border-gray-200">
      {hasPrevPage ? (
        <Link
          to={`${base}/${prevPage}`}
          className="p-4 text-pxblue-500 hover:bg-white"
        >
          ← <span className="hidden lg:inline">Anterior</span>
        </Link>
      ) : (
        <div className="p-4 text-pxtx-200">
          ← <span className="hidden lg:inline">Anterior</span>
        </div>
      )}
      {Array.from({ length: totalPages }).map((_, i) => (
        <Link
          className={
            currentPage === 1 && i === 0
              ? 'pagelink current hover:bg-white'
              : 'pagelink hover:bg-white'
          }
          to={`${base}/${i > 0 ? i + 1 : ''}`}
          key={`${base}-navpage-${i}`}
        >
          {i + 1}
        </Link>
      ))}
      {hasNextPage ? (
        <Link
          to={`${base}/${nextPage}`}
          className="p-4 text-pxblue-500 hover:bg-white"
        >
          <span className="hidden lg:inline">Siguiente</span> →
        </Link>
      ) : (
        <div className="p-4 text-pxtx">
          <span className="hidden lg:inline">Siguiente</span> →
        </div>
      )}
    </div>
  )
}
